module.exports = Object.freeze({
   //API_URL: "http://192.168.1.222:3000/api",
   API_URL: "https://apiqs.adconline.com.mx/api",
   FRONTEND_URL: "https://qs.adconline.com.mx/",
   APP_NAME: "Quick Support",
   REPORTE_MAX_IMGS: 3,
   DEVELOP_MODE: true,
   //###
   PERMISOS: {
      SUPER: "super",
      ADMIN: "admin",
      USER: "user",
      CREAR_REPORTES: "crear_reportes",
      CONSULTAR_REPORTES: "consultar_reportes",
      EDITAR_REPORTES: "editar_reportes",
      ELIMINAR_REPORTES: "eliminar_reportes",
      CREAR_CLIENTES: "crear_clientes",
      CONSULTAR_CLIENTES: "consultar_clientes",
      EDITAR_CLIENTES: "editar_clientes",
      ELIMINAR_CLIENTES: "eliminar_clientes",
      CONSULTAR_MOVIMIENTOS: "consultar_movimientos",
      CAMBIAR_TIPO_COBRO: "cambiar_tipo_cobro",
      AGREGAR_HORAS: "agregar_horas",
      QUITAR_HORAS: "quitar_horas",
      EDITAR_TELEFONO_AL_ENVIO: "editar_telefono_al_envio",
      EDITAR_CORREO_AL_ENVIO: "editar_correo_al_envio",
      CANCELAR_REPORTES: "cancelar_reportes",
      REENVIAR_REPORTE: "reenviar_reporte",
      EDITAR_NOTAS_DE_MOVIMIENTOS: "editar_notas_de_movimientos"
   }
});