import "@mdi/font/css/materialdesignicons.css";
import colors from "vuetify/lib/util/colors";
import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
   icons: {
      iconfont: "mdi",
   },
   theme: {
      themes: {
         dark: {
            primary: colors.blueGrey.darken4,
            secondary: colors.blueGrey.darken3,
            accent: colors.blueGrey.lighten2,
            error: colors.red.lighten1,
            warning: colors.orange.accent3,
            info: colors.blueGrey,
            success: colors.green.lighten1,
            background: colors.grey.darken4
         },
         light: {
            primary: colors.blueGrey.lighten1,
            secondary: colors.blueGrey.lighten3,
            accent: colors.blueGrey,
            error: colors.red.darken4,
            warning: colors.orange.accent3,
            info: colors.blueGrey.darken4,
            success: colors.green.darken1,
            background: colors.blueGrey.lighten5
         },
      },
      options: {
         customProperties: true,
      },
   },
});
