import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import axios from "axios";
import VueAxios from "vue-axios";
import JsonViewer from "vue-json-viewer";
import DatetimePicker from 'vuetify-datetime-picker'
import VueSignaturePad from 'vue-signature-pad';
import './registerServiceWorker'

Vue.use(VueSignaturePad);
Vue.use(VueAxios, axios);
Vue.use(JsonViewer);
Vue.use(DatetimePicker);
Vue.config.productionTip = false;

try {
   let userdata = JSON.parse(localStorage.getItem("userdata"));
   store.dispatch("relogin", userdata);
} catch (error) {}

router.beforeEach(async function (to, from, next) {
   try {
      let authorized = false;
      if (to.meta.require_permisos.length > 0) {
         const permisos_usuario = store.state.userdata.permisos;
         if (permisos_usuario.length > 0) {
            const permisos_view = to.meta.require_permisos;
            const match_permisos = permisos_usuario.filter((elem) => permisos_view.includes(elem));
            if (match_permisos.length > 0) {
               authorized = true;
            } else {
               authorized = false;
            }
         } else {
            authorized = false;
         }
      } else {
         authorized = true;
      }
      if (authorized == true) {
         if (to.meta.get.length > 0) {
            to.meta.get.forEach(async function (val, index, arr) {
               await store.dispatch(val);
               next();
            });
         } else {
            next();
         }
      } else {
         router.push({
            name: "Dashboard",
            query: {
               status: "denied",
               time: new Date().getTime(),
            },
         });
      }
   } catch (error) {
      next(error)
   }
});

new Vue({
   router,
   store,
   vuetify,
   render: (h) => h(App),
}).$mount("#app");
