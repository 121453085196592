import Vue from "vue";
import VueRouter from "vue-router";
import c from "../const.js";

Vue.use(VueRouter);

const routes = [
   {
      path: "/",
      name: "Main",
      component: () => import("../views/Main.vue"),
      meta: { require_permisos: [], get: [] },
   },
   {
      path: "/downloads",
      name: "Downloads",
      component: () => import("../views/Downloads.vue"),
      meta: { require_permisos: [], get: [] },
   },
   {
      path: "/login",
      name: "Login",
      component: () => import("../views/Login.vue"),
      meta: { require_permisos: [], get: [] },
   },
   {
      path: "/r",
      name: "Reporte",
      component: () => import("../views/Reporte.vue"),
      meta: { require_permisos: [], get: [] },
   },
   {
      path: "/about",
      name: "About",
      component: () => import("../views/About.vue"),
      meta: { require_permisos: [], get: [] },
   },
   {
      path: "/servicios",
      name: "Servicios",
      component: () => import("../views/Servicios.vue"),
      meta: { require_permisos: [], get: [] },
   },
   {
      path: "/contacto",
      name: "Contacto",
      component: () => import("../views/Contacto.vue"),
      meta: { require_permisos: [], get: [] },
   },
   {
      path: "/dashboard",
      name: "Dashboard",
      component: () => import("../views/Dashboard.vue"),
      meta: {
         require_permisos: [c.PERMISOS.SUPER, c.PERMISOS.ADMIN, c.PERMISOS.USER],
         get: ["getDashboard"],
      },
   },
   {
      path: "/dashboard/usuarios",
      name: "Usuarios",
      component: () => import("../views/Usuarios.vue"),
      meta: {
         require_permisos: [c.PERMISOS.SUPER, c.PERMISOS.ADMIN],
         get: ["getUsers", "getRoles", "getPermisos", "getProfile"],
      },
   },
   {
      path: "/dashboard/reportes",
      name: "Reportes",
      component: () => import("../views/Reportes.vue"),
      meta: {
         require_permisos: [c.PERMISOS.SUPER, c.PERMISOS.ADMIN, c.PERMISOS.CONSULTAR_REPORTES],
         get: ["getProfile"],
      },
   },
   {
      path: "/dashboard/movimientos",
      name: "Movimientos",
      component: () => import("../views/Movimientos.vue"),
      meta: {
         require_permisos: [c.PERMISOS.SUPER, c.PERMISOS.ADMIN, c.PERMISOS.CONSULTAR_MOVIMIENTOS],
         get: [],
      },
   },
   {
      path: "/dashboard/clientes",
      name: "Clientes",
      component: () => import("../views/Clientes.vue"),
      meta: {
         require_permisos: [c.PERMISOS.SUPER, c.PERMISOS.ADMIN, c.PERMISOS.CONSULTAR_CLIENTES],
         get: ["getProfile"],
      },
   },
   {
      path: "/dashboard/settings",
      name: "Settings",
      component: () => import("../views/Settings.vue"),
      meta: {
         require_permisos: [c.PERMISOS.SUPER, c.PERMISOS.ADMIN, c.PERMISOS.USER],
         get: ["getProfile"],
      },
   },
   {
      path: "/dashboard/vendedores",
      name: "Vendedores",
      component: () => import("../views/Vendedores.vue"),
      meta: { require_permisos: [c.PERMISOS.SUPER], get: ["getSellers"] },
   },
   {
      path: "/dashboard/updates",
      name: "Updates",
      component: () => import("../views/Updates.vue"),
      meta: { require_permisos: [c.PERMISOS.SUPER], get: ["getUpdates"] },
   },
   {
      path: "/dashboard/cuentas",
      name: "Cuentas",
      component: () => import("../views/Cuentas.vue"),
      meta: { require_permisos: [c.PERMISOS.SUPER], get: ["getSellers"] },
   },
   {
      path: "/dashboard/state",
      name: "State",
      component: () => import("../views/State.vue"),
      meta: { require_permisos: [c.PERMISOS.SUPER], get: [] },
   },
];

const router = new VueRouter({
   mode: "history",
   routes,
});

export default router;
