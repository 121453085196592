import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import router from "../router/index";
import c from "../const";
import moment from "moment";
import timezone from "moment-timezone";
import { formAxios } from "../modules/formAxios";

Vue.use(Vuex);

export default new Vuex.Store({
   state: {
      userdata: {
         nombre: "Usuario",
         token: "",
         permisos: [],
         caducidad: "",
      },
      perfil: {
         username: "",
         nombre: "",
         empresa: "",
         telefono: "",
         rfc: "",
         direccion: "",
         email: "",
         encabezado: "",
         logo: "",
         suscripcion: "",
         inf_email: {
            smtp: "",
            protocolo: "",
            remitente_email: "",
            puerto: "",
         },
         pegaso: {
            pegaso_password: "",
            pegaso_username: "",
            pegaso_type: "",
         },
      },
      reporte: {},
      cuentas: {
         accounts: [],
         totalItems: 10,
         options: {},
      },
      clientes: {
         customers: [],
         totalItems: 10,
         options: {},
      },
      movimientos: {
         movements: [],
         totalItems: 10,
         options: {},
      },
      reportes: {
         reports: [],
         totalItems: 10,
         options: {},
      },
      dashboard: {
         clientes: 0,
         reportes: 0,
         movimientos: 0,
      },
      users: [],
      roles: [],
      permisos: [],
      sellers: [],
      updates: [],
      fast_clientes: [],
      fast_usuarios: [],
      fast_admins: [],
      eventos: [],
      devices: [],
      available_updates: [],
   },
   mutations: {
      login(state, payload) {
         state.userdata.nombre = payload.nombre;
         state.userdata.token = payload.token;
         state.userdata.permisos = payload.permisos;
         state.userdata.caducidad = payload.caducidad;
         localStorage.setItem("userdata", JSON.stringify(payload));
      },
      logout(state) {
         state.userdata = {
            nombre: "Usuario",
            token: "",
            permisos: [],
            caducidad: "",
         };
         localStorage.setItem("userdata", "");
      },
      refresh(state, payload) {
         state.userdata.token = payload.token;
         state.userdata.caducidad = payload.caducidad;
         localStorage.setItem("userdata", JSON.stringify(state.userdata));
      },
      signReporte(state, payload) {
         state.reporte.firmado = payload.firmado;
      },
      //getters
      getDashboard(state, payload) {
         state.dashboard.clientes = payload.clientes;
         state.dashboard.reportes = payload.reportes;
         state.dashboard.movimientos = payload.movimientos;
      },
      getProfile(state, payload) {
         state.perfil = payload.perfil;
      },
      getUsers(state, payload) {
         state.users = payload;
      },
      getRoles(state, payload) {
         state.roles = payload;
      },
      getPermisos(state, payload) {
         state.permisos = payload;
      },
      getSellers(state, payload) {
         state.sellers = payload;
      },
      getUpdates(state, payload) {
         state.updates = payload;
      },
      getCuentas(state, payload) {
         state.cuentas.accounts = payload.accounts;
         state.cuentas.totalItems = payload.totalItems;
         state.cuentas.options = payload.options;
      },
      getClientes(state, payload) {
         state.clientes.customers = payload.customers;
         state.clientes.totalItems = payload.totalItems;
         state.clientes.options = payload.options;
      },
      getFastClientes(state, payload) {
         state.fast_clientes = payload.fast_clientes;
      },
      getFastUsuarios(state, payload) {
         state.fast_usuarios = payload.fast_usuarios;
      },
      getFastAdmins(state, payload) {
         state.fast_admins = payload.fast_admins;
      },
      getMovimientos(state, payload) {
         state.movimientos.movements = payload.movements;
         state.movimientos.totalItems = payload.totalItems;
         state.movimientos.options = payload.options;
      },
      getReportes(state, payload) {
         state.reportes.reports = payload.reports;
         state.reportes.totalItems = payload.totalItems;
         state.reportes.options = payload.options;
      },
      getReporte(state, payload) {
         state.reporte.folio = payload.folio;
         state.reporte.header = payload.header;
         state.reporte.empresa = payload.empresa;
         state.reporte.atendio = payload.atendio;
         state.reporte.descripcion = payload.descripcion;
         state.reporte.fecha_hora = payload.fecha_hora;
         state.reporte.importe = payload.importe_horas;
         state.reporte.tipo_cobro = payload.tipo_cobro;
         state.reporte.notas = payload.notas;
         state.reporte.solicito = payload.solicito;
         state.reporte.imgs = payload.imgs;
         state.reporte.firmado = payload.firmados;
         state.reporte.cancelado = payload.cancelado;
      },
      getEventos(state, payload) {
         state.eventos = payload.eventos;
      },
      getDevices(state, payload) {
         state.devices = payload.devices;
      },
      getAvailableUpdates(state, payload) {
         state.available_updates = payload.available_updates;
      },
   },
   actions: {
      async login(context, payload) {
         try {
            let userdata = await axios.post(`${c.API_URL}/login`, {
               username: payload.username,
               password: payload.password,
            });
            context.commit("login", userdata.data);
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async relogin(context, payload) {
         try {
            let userdata = {
               nombre: payload.nombre,
               token: payload.token,
               permisos: payload.permisos,
               caducidad: payload.caducidad,
            };
            if (moment().isBefore(moment(userdata.caducidad))) {
               context.commit("login", userdata);
            }
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      logout(context) {
         try {
            context.commit("logout");
         } catch (error) {}
      },
      async refresh({ commit, state, dispatch }) {
         try {
            let refresh_data = await axios.get(`${c.API_URL}/refresh`, {
               headers: {
                  Authorization: "Bearer " + state.userdata.token,
               },
            });
            commit("refresh", refresh_data.data);
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            dispatch("logout");
            router.push({
               name: "Login",
               query: {
                  status: "expired",
                  time: new Date().getTime(),
               },
            });
         }
      },
      checkPermiso: function (context, payload) {
         if (payload.length > 0) {
            const permisos_usuario = context.state.userdata.permisos;
            if (permisos_usuario.length > 0) {
               if (permisos_usuario.includes(c.PERMISOS.SUPER) || permisos_usuario.includes(c.PERMISOS.ADMIN)) {
                  return true;
               }
               const match_permisos = permisos_usuario.filter((elem) => payload.includes(elem));
               if (match_permisos.length > 0) {
                  return true;
               } else {
                  return false;
               }
            } else {
               return false;
            }
         } else {
            return true;
         }
      },
      signReporte: async function (context, payload) {
         try {
            await axios.put(`${c.API_URL}/reports/sign`, {
               token: payload.token,
            });
            context.commit("signReporte", { firmado: "S" });
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      //Getters
      async getDashboard(context, payload) {
         try {
            let data = await axios.get(`${c.API_URL}/dashboard`, {
               headers: {
                  Authorization: "Bearer " + context.state.userdata.token,
               },
            });
            context.commit("getDashboard", data.data.data);
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
         }
      },
      async getProfile(context, payload) {
         try {
            let data = await axios.get(`${c.API_URL}/users/profile`, {
               headers: {
                  Authorization: "Bearer " + context.state.userdata.token,
               },
            });
            context.commit("getProfile", data.data);
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error);
            }
         }
      },
      async getUsers(context, payload) {
         try {
            let data = await axios.get(`${c.API_URL}/users/subusers`, {
               headers: {
                  Authorization: "Bearer " + context.state.userdata.token,
               },
            });
            context.commit("getUsers", data.data.result);
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async getRoles(context, payload) {
         try {
            let data = await axios.get(`${c.API_URL}/users/roles`, {
               headers: {
                  Authorization: "Bearer " + context.state.userdata.token,
               },
            });
            context.commit("getRoles", data.data.roles);
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async getPermisos(context, payload) {
         try {
            let data = await axios.get(`${c.API_URL}/users/permissions`, {
               headers: {
                  Authorization: "Bearer " + context.state.userdata.token,
               },
            });
            context.commit("getPermisos", data.data.permisos);
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async getSellers(context, payload) {
         try {
            let data = await axios.get(`${c.API_URL}/super/sellers`, {
               headers: {
                  Authorization: "Bearer " + context.state.userdata.token,
               },
            });
            context.commit("getSellers", data.data.sellers);
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async getUpdates(context, payload) {
         try {
            let data = await axios.get(`${c.API_URL}/agent/apps`, {
               headers: {
                  Authorization: "Bearer " + context.state.userdata.token,
               },
            });
            context.commit("getUpdates", data.data.data);
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async getCuentas(context, payload) {
         try {
            if (!payload) {
               payload = context.state.cuentas.options;
            }
            let data = await axios.get(`${c.API_URL}/super/accounts`, {
               headers: {
                  Authorization: "Bearer " + context.state.userdata.token,
               },
               params: payload,
            });
            context.commit("getCuentas", {
               accounts: data.data.datatable.accounts,
               totalItems: data.data.datatable.totalItems,
               options: payload,
            });
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async getClientes(context, payload) {
         try {
            if (!payload) {
               payload = context.state.clientes.options;
            }
            let data = await axios.get(`${c.API_URL}/customers`, {
               headers: {
                  Authorization: "Bearer " + context.state.userdata.token,
               },
               params: payload,
            });
            context.commit("getClientes", {
               customers: data.data.datatable.customers,
               totalItems: data.data.datatable.totalItems,
               options: payload,
            });
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async getFastClientes(context, payload) {
         try {
            let data = await axios.get(`${c.API_URL}/reports/customer`, {
               headers: {
                  Authorization: "Bearer " + context.state.userdata.token,
               },
               params: {
                  search: payload.search,
               },
            });
            context.commit("getFastClientes", {
               fast_clientes: data.data.search,
            });
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async getFastUsuarios(context, payload) {
         try {
            let data = await axios.get(`${c.API_URL}/reports/attended`, {
               headers: {
                  Authorization: "Bearer " + context.state.userdata.token,
               },
               params: {
                  search: payload.search,
               },
            });
            context.commit("getFastUsuarios", {
               fast_usuarios: data.data.search,
            });
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async getFastAdmins(context, payload) {
         try {
            let data = await axios.get(`${c.API_URL}/super/user`, {
               headers: {
                  Authorization: "Bearer " + context.state.userdata.token,
               },
               params: {
                  search: payload.search,
               },
            });
            context.commit("getFastAdmins", {
               fast_admins: data.data.search,
            });
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async getReportes(context, payload) {
         try {
            if (!payload) {
               payload = context.state.reportes.options;
            }
            let data = await axios.get(`${c.API_URL}/reports`, {
               headers: {
                  Authorization: "Bearer " + context.state.userdata.token,
               },
               params: payload,
            });
            context.commit("getReportes", {
               reports: data.data.datatable.reports,
               totalItems: data.data.datatable.totalItems,
               options: payload,
            });
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async getReporte(context, payload) {
         try {
            if (!payload) {
               throw "no se han enviado parametros";
            }
            let data = await axios.get(`${c.API_URL}/reports/report`, {
               data: {},
               params: {
                  token: payload.token,
                  TZ: timezone.tz.guess(),
               },
            });
            context.commit("getReporte", data.data);
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async getDevices(context, payload) {
         try {
            if (!payload) {
               throw "no se han enviado parametros";
            }
            let data = await axios.get(`${c.API_URL}/agent/devices`, {
               data: {},
               headers: {
                  Authorization: "Bearer " + context.state.userdata.token,
               },
               params: {
                  id: payload.id,
               },
            });
            context.commit("getDevices", { devices: data.data });
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async getAvailableUpdates(context, payload) {
         try {
            let data = await axios.get(`${c.API_URL}/users/apps`, {
               headers: {
                  Authorization: "Bearer " + context.state.userdata.token,
               },
            });
            context.commit("getAvailableUpdates", { available_updates: data.data.data });
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async getReportesExcel(context, payload) {
         try {
            if (!payload) {
               throw "no se han enviado parametros";
            }
            let data = await axios.get(`${c.API_URL}/reports/excel`, {
               headers: {
                  Authorization: "Bearer " + context.state.userdata.token,
               },
               responseType: "blob",
               data: {},
               params: {
                  init: payload.init,
                  final: payload.final,
                  type: payload.type,
                  id_cliente: payload.id_cliente ? payload.id_cliente : "",
                  id_user: payload.id_user ? payload.id_user : "",
                  TZ: payload.TZ ? payload.TZ : "",
               },
            });
            return data;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async getMovimientos(context, payload) {
         try {
            if (!payload) {
               payload = context.state.movimientos.options;
            }
            let data = await axios.get(`${c.API_URL}/movements`, {
               headers: {
                  Authorization: "Bearer " + context.state.userdata.token,
               },
               params: payload,
            });
            context.commit("getMovimientos", {
               movements: data.data.datatable.movements,
               totalItems: data.data.datatable.totalItems,
               options: payload,
            });
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async getMovimientosExcel(context, payload) {
         try {
            if (!payload) {
               throw "no se han enviado parametros";
            }
            let data = await axios.get(`${c.API_URL}/movements/excel`, {
               headers: {
                  Authorization: "Bearer " + context.state.userdata.token,
               },
               responseType: "blob",
               data: {},
               params: {
                  init: payload.init,
                  final: payload.final,
                  TZ: timezone.tz.guess(),
               },
            });
            return data;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async getEventos(context, payload) {
         try {
            let data = await axios.get(`${c.API_URL}/dashboard/agenda`, {
               headers: {
                  Authorization: "Bearer " + context.state.userdata.token,
               },
               params: {
                  all: payload.all == true ? "S" : "N",
                  fecha_init: payload.fecha_init,
                  fecha_final: payload.fecha_final,
                  TZ: timezone.tz.guess(),
               },
            });
            context.commit("getEventos", {
               eventos: data.data,
            });
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async getClienteByID(context, payload) {
         try {
            if (!payload.id) {
               return;
            }
            let data = await axios.get(`${c.API_URL}/customers/customer`, {
               headers: {
                  Authorization: "Bearer " + context.state.userdata.token,
               },
               params: {
                  id: payload.id,
               },
            });
            return data.data;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      //Inserts
      async insertUser(context, payload) {
         try {
            await axios.post(`${c.API_URL}/users/subusers`, payload, {
               headers: {
                  Authorization: "Bearer " + context.state.userdata.token,
               },
            });
            await context.dispatch("getUsers");
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async insertRol(context, payload) {
         try {
            await axios.post(`${c.API_URL}/users/roles`, payload, {
               headers: {
                  Authorization: "Bearer " + context.state.userdata.token,
               },
            });
            await context.dispatch("getRoles");
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async insertSeller(context, payload) {
         try {
            await axios.post(`${c.API_URL}/super/sellers`, payload, {
               headers: {
                  Authorization: "Bearer " + context.state.userdata.token,
               },
            });
            await context.dispatch("getSellers");
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async insertUpdate(context, payload) {
         try {
            await axios.post(`${c.API_URL}/agent/apps`, payload, {
               headers: {
                  Authorization: "Bearer " + context.state.userdata.token,
               },
            });

            await context.dispatch("getUpdates");
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async insertDevicesUpdates(context, payload) {
         try {
            console.log(payload);
            await axios.post(`${c.API_URL}/agent/updates`, payload, {
               headers: {
                  Authorization: "Bearer " + context.state.userdata.token,
               },
            });
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async insertCuenta(context, payload) {
         try {
            await axios.post(`${c.API_URL}/super/accounts`, payload, {
               headers: {
                  Authorization: "Bearer " + context.state.userdata.token,
               },
            });
            await context.dispatch("getCuentas");
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async insertCliente(context, payload) {
         try {
            await axios.post(
               `${c.API_URL}/customers`,
               {
                  nombre: payload.nombre,
                  empresa: payload.empresa,
                  telefono: payload.telefono,
                  rfc: payload.rfc,
                  email: payload.email,
                  condicion_pago: payload.condicion_pago,
                  tipo_cobro: payload.tipo_cobro,
                  horas: payload.horas,
                  TZ: timezone.tz.guess(),
               },
               {
                  headers: {
                     Authorization: "Bearer " + context.state.userdata.token,
                  },
               }
            );
            await context.dispatch("getClientes");
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async insertEvento(context, payload) {
         try {
            await axios.post(
               `${c.API_URL}/dashboard/agenda`,
               {
                  nombre: payload.nombre,
                  fecha_init: payload.fecha_init,
                  fecha_final: payload.fecha_final,
                  color: payload.color,
                  id_cliente: payload.id_cliente,
                  id_sub_usuario: payload.id_sub_usuario,
                  descripcion: payload.descripcion,
                  alerta: payload.alerta,
               },
               {
                  headers: {
                     Authorization: "Bearer " + context.state.userdata.token,
                  },
               }
            );
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async insertReporte(context, payload) {
         try {
            await formAxios.post(`${c.API_URL}/reports`, payload, {
               headers: {
                  Authorization: "Bearer " + context.state.userdata.token,
                  "Content-Type": "multipart/form-data",
                  charset: "utf-8",
                  boundary: Math.random().toString().substring(2),
               },
            });
            await context.dispatch("getReportes");
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error.response;
         }
      },
      async reenviarReporte(context, payload) {
         try {
            await axios.post(`${c.API_URL}/reports/resend`, payload, {
               headers: {
                  Authorization: "Bearer " + context.state.userdata.token,
               },
            });
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async addHoras(context, payload) {
         try {
            await axios.post(`${c.API_URL}/movements/add`, payload, {
               headers: {
                  Authorization: "Bearer " + context.state.userdata.token,
               },
            });
            await context.dispatch("getClientes");
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async lessHoras(context, payload) {
         try {
            await axios.post(`${c.API_URL}/movements/sub`, payload, {
               headers: {
                  Authorization: "Bearer " + context.state.userdata.token,
               },
            });
            await context.dispatch("getClientes");
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      //Updates
      async updateMov(context, payload) {
         try {
            await axios.put(
               `${c.API_URL}/movements/edit`,
               payload,
               {
                  headers: {
                     Authorization: "Bearer " + context.state.userdata.token,
                  },
               }
            );
            await context.dispatch("getMovimientos");
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async updateUser(context, payload) {
         try {
            await axios.put(`${c.API_URL}/users/subusers`, payload, {
               headers: {
                  Authorization: "Bearer " + context.state.userdata.token,
               },
            });
            await context.dispatch("getUsers");
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async updateRol(context, payload) {
         try {
            await axios.put(`${c.API_URL}/users/roles`, payload, {
               headers: {
                  Authorization: "Bearer " + context.state.userdata.token,
               },
            });
            await context.dispatch("getRoles");
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async updateSeller(context, payload) {
         try {
            await axios.put(`${c.API_URL}/super/sellers`, payload, {
               headers: {
                  Authorization: "Bearer " + context.state.userdata.token,
               },
            });
            await context.dispatch("getSellers");
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async updateUpdates(context, payload) {
         try {
            await axios.put(`${c.API_URL}/agent/apps`, payload, {
               headers: {
                  Authorization: "Bearer " + context.state.userdata.token,
               },
            });
            await context.dispatch("getUpdates");
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async updateCuenta(context, payload) {
         try {
            console.log(payload);
            await axios.put(`${c.API_URL}/super/accounts`, payload, {
               headers: {
                  Authorization: "Bearer " + context.state.userdata.token,
               },
            });
            await context.dispatch("getCuentas");
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async updateCliente(context, payload) {
         try {
            await axios.put(`${c.API_URL}/customers`, payload, {
               headers: {
                  Authorization: "Bearer " + context.state.userdata.token,
               },
            });
            await context.dispatch("getClientes");
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async updateEvento(context, payload) {
         try {
            await axios.put(
               `${c.API_URL}/dashboard/agenda`,
               {
                  id: payload.id_agenda,
                  nombre: payload.nombre,
                  fecha_init: payload.fecha_init,
                  fecha_final: payload.fecha_final,
                  color: payload.color,
                  id_cliente: payload.id_cliente,
                  id_sub_usuario: payload.id_sub_usuario,
                  descripcion: payload.descripcion,
                  alerta: payload.alerta,
               },
               {
                  headers: {
                     Authorization: "Bearer " + context.state.userdata.token,
                  },
               }
            );
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async updateEmail(context, payload) {
         try {
            let data = await axios.put(
               `${c.API_URL}/users/mail`,
               {
                  smtp: payload.smtp,
                  email: payload.email,
                  pass: payload.pass,
                  puerto: payload.puerto,
                  protocolo: payload.protocolo,
               },
               {
                  headers: {
                     Authorization: "Bearer " + context.state.userdata.token,
                  },
               }
            );
            await context.dispatch("getProfile");
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async updatePegaso(context, payload) {
         try {
            let data = await axios.put(
               `${c.API_URL}/users/pegaso`,
               {
                  user: payload.user,
                  pass: payload.pass,
                  type: payload.type,
               },
               {
                  headers: {
                     Authorization: "Bearer " + context.state.userdata.token,
                  },
               }
            );
            await context.dispatch("getProfile");
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async updateProfile(context, payload) {
         try {
            let data = await axios.put(
               `${c.API_URL}/users/profile`,
               {
                  nombre: payload.nombre,
                  telefono: payload.telefono,
                  rfc: payload.rfc,
                  email: payload.email,
                  empresa: payload.empresa,
                  direccion: payload.direccion,
               },
               {
                  headers: {
                     Authorization: "Bearer " + context.state.userdata.token,
                  },
               }
            );
            await context.dispatch("getProfile");
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async updatePassword(context, payload) {
         try {
            await axios.put(
               `${c.API_URL}/users/password`,
               {
                  password: payload.password,
               },
               {
                  headers: {
                     Authorization: "Bearer " + context.state.userdata.token,
                  },
               }
            );
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async updateAPIKEY(context, payload) {
         try {
            await axios.put(
               `${c.API_URL}/users/synckey`,
               {},
               {
                  headers: {
                     Authorization: "Bearer " + context.state.userdata.token,
                  },
               }
            );
            await context.dispatch("getProfile");
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async updateLogo(context, payload) {
         try {
            await axios.put(`${c.API_URL}/users/logo`, payload, {
               headers: {
                  Authorization: "Bearer " + context.state.userdata.token,
                  "Content-Type": "multipart/form-data",
               },
            });
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async updateEncabezado(context, payload) {
         try {
            await axios.put(`${c.API_URL}/users/header`, payload, {
               headers: {
                  Authorization: "Bearer " + context.state.userdata.token,
                  "Content-Type": "multipart/form-data",
               },
            });
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async addMonth(context, payload) {
         try {
            await axios.put(`${c.API_URL}/super/subscription`, payload, {
               headers: {
                  Authorization: "Bearer " + context.state.userdata.token,
               },
            });
            await context.dispatch("getCuentas");
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async lessMonth(context, payload) {
         try {
            await axios.put(`${c.API_URL}/super/subscription`, payload, {
               headers: {
                  Authorization: "Bearer " + context.state.userdata.token,
               },
            });
            await context.dispatch("getCuentas");
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async renewClienteApiKey(context, payload) {
         try {
            console.log(payload);
            await axios.put(`${c.API_URL}/customers/apikey`, payload, {
               headers: {
                  Authorization: "Bearer " + context.state.userdata.token,
               },
            });
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      //Deletes
      async deleteLogo(context) {
         try {
            await axios.delete(`${c.API_URL}/users/logo`, {
               headers: {
                  Authorization: "Bearer " + context.state.userdata.token,
               },
            });
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async deleteEncabezado(context) {
         try {
            await axios.delete(`${c.API_URL}/users/header`, {
               headers: {
                  Authorization: "Bearer " + context.state.userdata.token,
               },
            });
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async deleteUser(context, payload) {
         try {
            await axios.delete(`${c.API_URL}/users/subusers`, {
               headers: {
                  Authorization: "Bearer " + context.state.userdata.token,
               },
               data: { id: payload.id },
            });
            await context.dispatch("getUsers");
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async deleteRol(context, payload) {
         try {
            await axios.delete(`${c.API_URL}/users/roles`, {
               headers: {
                  Authorization: "Bearer " + context.state.userdata.token,
               },
               data: { id_rol: payload.id_rol },
            });
            context.dispatch("getRoles");
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async deleteSeller(context, payload) {
         try {
            await axios.delete(`${c.API_URL}/super/sellers`, {
               headers: {
                  Authorization: "Bearer " + context.state.userdata.token,
               },
               data: { id: payload.id },
            });
            context.dispatch("getSellers");
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async deleteUpdate(context, payload) {
         try {
            await axios.delete(`${c.API_URL}/agent/apps`, {
               headers: {
                  Authorization: "Bearer " + context.state.userdata.token,
               },
               data: { id: payload.id },
            });
            await context.dispatch("getUpdates");
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async deleteDeviceUpdate(context, payload) {
         try {
            console.log(payload);
            await axios.delete(`${c.API_URL}/agent/updates`, {
               headers: {
                  Authorization: "Bearer " + context.state.userdata.token,
               },
               data: { id: payload.id },
            });
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async deleteDevice(context, payload) {
         try {
            await axios.delete(`${c.API_URL}/agent/devices`, {
               headers: {
                  Authorization: "Bearer " + context.state.userdata.token,
               },
               data: { id: payload.id },
            });
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async deleteCuenta(context, payload) {
         try {
            await axios.delete(`${c.API_URL}/super/accounts`, {
               headers: {
                  Authorization: "Bearer " + context.state.userdata.token,
               },
               data: { id: payload.id },
            });
            context.dispatch("getCuentas");
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async deleteCliente(context, payload) {
         try {
            await axios.delete(`${c.API_URL}/customers`, {
               headers: {
                  Authorization: "Bearer " + context.state.userdata.token,
               },
               data: { id_cliente: payload.id },
            });
            context.dispatch("getClientes");
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async deleteEvento(context, payload) {
         try {
            console.log(payload);
            await axios.delete(`${c.API_URL}/dashboard/agenda`, {
               headers: {
                  Authorization: "Bearer " + context.state.userdata.token,
               },
               data: { id: payload.id_agenda },
            });
            context.dispatch("getClientes");
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
      async cancelReporte(context, payload) {
         try {
            console.log(payload);
            await axios.delete(`${c.API_URL}/reports`, {
               headers: {
                  Authorization: "Bearer " + context.state.userdata.token,
               },
               data: { id: payload.id_reporte },
            });
            context.dispatch("getReports");
            return true;
         } catch (error) {
            if (c.DEVELOP_MODE) {
               console.log(error.response);
            }
            return error;
         }
      },
   },
   getters: {
      isSesionActive: function (state, getters) {
         if (state.userdata.token.trim().length > 0) {
            return true;
         } else {
            return false;
         }
      },
      userType: function (state) {
         if (state.userdata.permisos.includes(c.PERMISOS.SUPER)) {
            return c.PERMISOS.SUPER;
         } else if (state.userdata.permisos.includes(c.PERMISOS.ADMIN)) {
            return c.PERMISOS.ADMIN;
         } else if (state.userdata.permisos.includes(c.PERMISOS.USER)) {
            return c.PERMISOS.USER;
         } else {
            return "";
         }
      },
      isSuperOrAdmin: function (state) {
         if (state.userdata.permisos.includes(c.PERMISOS.SUPER)) {
            return true;
         } else if (state.userdata.permisos.includes(c.PERMISOS.ADMIN)) {
            return true;
         } else if (state.userdata.permisos.includes(c.PERMISOS.USER)) {
            return false;
         } else {
            return false;
         }
      },
      isSuper: function (state) {
         if (state.userdata.permisos.includes(c.PERMISOS.SUPER)) {
            return true;
         } else if (state.userdata.permisos.includes(c.PERMISOS.ADMIN)) {
            return false;
         } else if (state.userdata.permisos.includes(c.PERMISOS.USER)) {
            return false;
         } else {
            return false;
         }
      },
      isSubUser: function (state) {
         if (state.userdata.permisos.includes(c.PERMISOS.SUPER)) {
            return false;
         } else if (state.userdata.permisos.includes(c.PERMISOS.ADMIN)) {
            return false;
         } else if (state.userdata.permisos.includes(c.PERMISOS.USER)) {
            return true;
         } else {
            return false;
         }
      },
      isMicrosip: function (state) {
         if (state.perfil.microsip == "S") {
            return true;
         } else {
            return false;
         }
      },
   },
});
