<template>
   <div class="header-container">
      <v-app-bar color="primary" dark>
         <v-app-bar-nav-icon @click="sidebar"></v-app-bar-nav-icon>
         <HeaderTitle />
         <v-spacer></v-spacer>
         <v-banner class="d-none d-sm-flex" single-line style="font-size: 17px">{{ this.$store.state.userdata.nombre.substring(0, 30) }}</v-banner>
         <HeaderLogin />
         <HeaderLogout />
      </v-app-bar>
      <HeaderSesionNotification />
      <Sidebar ref="sidebar" />
   </div>
</template>

<script>
import Sidebar from "./Sidebar.vue";
import HeaderTitle from "./Header/HeaderTitle.vue";
import HeaderLogin from "./Header/HeaderLogin.vue";
import HeaderLogout from "./Header/HeaderLogout.vue";
import HeaderSesionNotification from "./Header/HeaderSesionNotification.vue";

export default {
   data: () => ({
      drawer: false,
   }),
   components: {
      Sidebar,
      HeaderTitle,
      HeaderLogin,
      HeaderLogout,
      HeaderSesionNotification,
   },
   methods: {
      sidebar: function () {
         this.$refs.sidebar.toggle();
      },
   },
};
</script>
