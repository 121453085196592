<template>
   <v-footer padless absolute color="primary" dark>
      <v-row justify="center" no-gutters>
         <v-col class="text-center" cols="12">
            {{ new Date().getFullYear() }}
            <v-btn icon x-small @click="$router.push({ name: 'State' }, () => {})">—</v-btn>
            <v-icon small> mdi-copyright </v-icon>
            {{ name }}
         </v-col>
      </v-row>
   </v-footer>
</template>

<script>
import c from "../const";

export default {
   name: "Footer",
   data: () => {
      return {
         name: c.APP_NAME,
      };
   },
};
</script>

<style></style>
