<template>
   <v-app>
      <v-main>
         <Header />
         <router-view style="height: calc(100vh - 100px); overflow-y: auto" />
         <Footer />
      </v-main>
   </v-app>
</template>

<script>
import Header from "./components/Header.vue";
import Footer from "./components/Footer.vue";

export default {
   name: "App",
   data: () => ({}),
   components: {
      Header,
      Footer,
   },
   mounted: function () {
      if (localStorage.getItem("darkmode") == "false") {
         this.$vuetify.theme.dark = false;
      } else {
         localStorage.setItem("darkmode", true);
         this.$vuetify.theme.dark = true;
      }
   },
};
</script>

<style scoped>
.v-application {
   background-color: var(--v-background-base) !important;
}
</style>
