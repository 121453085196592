<template>
   <div class="d-flex align-center">
      <v-toolbar-title class="d-none d-sm-flex">
         <v-btn color="primary" @click="titleClick"><v-icon small>mdi-handshake-outline</v-icon>{{ name }} </v-btn>
      </v-toolbar-title>
      <v-btn color="accent" @click="titleClick" icon class="d-flex d-sm-none">
         <v-icon>mdi-handshake-outline</v-icon>
      </v-btn>
   </div>
</template>

<script>
import c from "../../const";

export default {
   data: () => {
      return {
         name: c.APP_NAME,
      };
   },
   methods: {
      titleClick: async function () {
         let is_sesion = await this.$store.getters.isSesionActive;
         if (is_sesion == true) {
            this.$router.push({ name: "Dashboard" }, () => {});
         } else {
            this.$router.push("/", () => {});
         }
      },
   },
};
</script>
