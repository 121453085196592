<template>
   <v-navigation-drawer v-model="sidebar" temporary height="100vh" absolute color="secondary">
      <v-list-item>
         <v-list-item-content>
            <v-list-item-title class="text-h6">{{ name }}</v-list-item-title>
            <v-list-item-subtitle>{{ this.$store.state.userdata.nombre.substring(0, 30) }}</v-list-item-subtitle>
         </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-list dense>
         <v-list-item-group>
            <SidebarButton v-if="hideOnSesion" path_name="Servicios" title="Servicios" icon="mdi-tools" />
            <SidebarButton v-if="hideOnSesion" path_name="About" title="Acerca de" icon="mdi-information" />
            <SidebarButton v-if="hideOnSesion" path_name="Contacto" title="Contacto" icon="mdi-card-account-details" />
            <v-divider v-if="hideOnSesion"></v-divider>
            <div v-if="User">
               <SidebarButton path_name="Dashboard" title="Dashboard" icon="mdi-home" />
               <SidebarButton path_name="Settings" title="Configuración" icon="mdi-cogs" />
               <SidebarButton path_name="Reportes" title="Reportes" icon="mdi-clipboard-list-outline" />
               <SidebarButton path_name="Clientes" title="Clientes" icon="mdi-account-group" />
               <SidebarButton path_name="Movimientos" title="Movimientos" icon="mdi-swap-horizontal" />
               <SidebarButton path_name="Downloads" title="Descargas" icon="mdi-package-down" />
            </div>
            <div v-if="Admin">
               <SidebarButton path_name="Usuarios" title="Usuarios" icon="mdi-account-cog" />
            </div>
            <div v-if="Super">
               <v-divider></v-divider>
               <SidebarButton path_name="Vendedores" title="Vendedores" icon="mdi-account-settings" />
               <SidebarButton path_name="Updates" title="Updates" icon="mdi-package-up" />
               <SidebarButton path_name="Cuentas" title="Cuentas" icon="mdi-account-plus" />
            </div>
         </v-list-item-group>
      </v-list>
   </v-navigation-drawer>
</template>

<script>
import c from "../const";
import SidebarButton from "./Sidebar/SidebarButton.vue";

export default {
   computed: {
      Super: function () {
         return this.$store.state.userdata.permisos.includes(c.PERMISOS.SUPER);
      },
      Admin: function () {
         return this.$store.state.userdata.permisos.includes(c.PERMISOS.ADMIN) || this.Super;
      },
      User: function () {
         return this.$store.state.userdata.permisos.includes(c.PERMISOS.USER) || this.Admin;
      },
      hideOnSesion: function () {
         return this.$store.state.userdata.permisos.length == 0;
      },
   },
   data: function () {
      return {
         sidebar: false,
         name: c.APP_NAME,
      };
   },
   methods: {
      toggle: function () {
         this.sidebar = !this.sidebar;
      },
   },
   components: {
      SidebarButton,
   },
};
</script>
