<template>
   <div>
      <v-dialog v-model="show" max-width="290">
         <v-card>
            <v-card-title>La sesión esta por vencer. ¿Desea continuar usando {{ name }}?</v-card-title>
            <v-card-actions>
               <v-spacer></v-spacer>
               <v-btn text @click="logout">Cerrar sesión</v-btn>
               <v-btn color="primary" text @click="refresh">Continuar</v-btn>
            </v-card-actions>
         </v-card>
      </v-dialog>
   </div>
</template>

<script>
import c from "../../const";
import moment from "moment";

export default {
   data: () => {
      return {
         name: c.APP_NAME,
         show: false,
      };
   },
   mounted() {
      setInterval(() => {
         if (this.$store.getters.isSesionActive) {
            if (moment().isAfter(moment(this.$store.state.userdata.caducidad).subtract(5, "m"))) {
               this.show = true;
            }
         }
      }, 5000);
   },
   methods: {
      logout: function () {
         try {
            this.$store.dispatch("logout");
            this.$router.push("/", () => {});
         } catch (error) {
         } finally {
            this.show = false;
         }
      },
      refresh: function () {
         try {
            this.$store.dispatch("refresh");
         } catch (error) {
         } finally {
            this.show = false;
         }
      },
   },
};
</script>

<style></style>
