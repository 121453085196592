<template>
   <v-list-item @click="$router.push({ name: path_name }, () => {})">
      <v-list-item-icon>
         <v-icon dense>{{ icon }}</v-icon>
      </v-list-item-icon>
      <v-list-item-title>{{ title }}</v-list-item-title>
   </v-list-item>
</template>

<script>
export default {
   props: {
      path_name: String,
      icon: String,
      title: String,
   },
};
</script>
