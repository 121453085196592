<template>
   <div v-if="$store.getters.isSesionActive">
      <v-tooltip bottom>
         <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on" @click="$refs.dialog.Show()">
               <v-icon small>mdi-logout-variant</v-icon>
            </v-btn>
         </template>
         <span>Cerrar sesión</span>
      </v-tooltip>
      <Dialog message="¿Desea cerrar la sesión?" ref="dialog" @result_ok="Logout" />
   </div>
</template>

<script>
import Dialog from "../Dialog.vue";

export default {
   components: {
      Dialog,
   },
   methods: {
      Logout: function () {
         try {
            this.$store.dispatch("logout");
            this.$router.push("/", () => {});
         } catch (error) {}
      },
   },
};
</script>
